// import { useRef } from "react";
// import Header from "../Components/Header";
// import Hero from "../Components/Hero";
// import Footer from '../Components/Footer';
// import Quote from "../Components/Quote";
// import ColumnsBoxes from "../Components/ColumnsBoxes";
// import ColumnsText from "../Components/ColumnsText";
// import CircleGraph from "../Components/CircleGraph";
// import ArticleText from '../Components/ArticleText';
// import ArticleTextBg from '../Components/ArticleTextBg';
// import Recommend from "../Components/Recommend";
// import Video from "../Components/Video";
// import CookieConsent from "react-cookie-consent";
import { pushGTM } from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';
import Wheel from "../Components/Wheel";
// import ArticleTextOneCol from "../Components/ArticleTextOneCol";

const Home = ({lang}) => {
	localStorage.setItem('lang', lang);
    i18next.changeLanguage(lang);
	localStorage.setItem('LightboxImage', 'standard_image');

	pushGTM('page_loaded_'+ lang, true)

	// menu + scrollTo
	// const scrollToTop = useRef(null);
	// const scrollTo1 = useRef(null), scrollToVideo = useRef(null), scrollTo2 = useRef(null), scrollTo4 = useRef(null), scrollTo5 = useRef(null), scrollTo6 = useRef(null), scrollTo7 = useRef(null), scrollTo8 = useRef(null), scrollTo9 = useRef(null);
	// const scrollTo3 = useRef(null);
	// const menuItemRes = [scrollTo1, scrollToVideo, scrollTo2, scrollTo3, scrollTo4, scrollTo5, scrollTo6, scrollTo7, scrollTo8, scrollTo9];

	return (
		<div className="page">
			
			
			{/* <div className="content_margin_top" ref={scrollToTop}></div> */}
			
			<main>
				{/* <div className="scroll_to_me" ref={scrollTo3}></div> */}
				<Wheel lang={lang} />
			</main>
		</div> // page
	);
}
 
export default Home;