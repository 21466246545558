import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { gsap } from "gsap";
import Accordion from './Accordion';
import { useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import design_arrow from '../assets/img/wheel/design_arrow.svg';
import design_cover from '../assets/img/wheel/cover.svg';
import icon_design from '../assets/img/wheel/icon_00.svg';
import arrow_circle_SE from '../assets/img/wheel/arrow_w_text_SE.svg';
import arrow_circle_ENG from '../assets/img/wheel/arrow_w_text_ENG.svg';
import { pushGTM } from '../Functions/useGTM';

// i18
import i18next from 'i18next';
import '../i18n';

const Wheel = ({lang}) => {
	const ref = useRef();
	const q = gsap.utils.selector(ref);

	const slice1 = useRef(null);
	const slice2 = useRef(null);
	const slice3 = useRef(null);
	const slice4 = useRef(null);
	const slice5 = useRef(null);
	const slice6 = useRef(null);

	const slice_array = [slice1, slice2, slice3, slice4, slice5, slice6];

	const text1 = useRef(null);
	const text2 = useRef(null);
	const text3 = useRef(null);
	const text4 = useRef(null);
	const text5 = useRef(null);
	const text6 = useRef(null);

	const texts_array = [text1, text2, text3, text4, text5, text6];

	const article = i18next.t('wheel', { returnObjects: true });
	const wheel_info = i18next.t('wheel_info', { returnObjects: true });

	let arrow_circle;
	if(lang === 'se'){
		arrow_circle = arrow_circle_SE;
	}

	if (lang === 'en') {
		arrow_circle = arrow_circle_ENG;
	}

	let texts = [];
	for (var i = 0; i < 6; i++) {
		texts[i] = [article[i][0], article[i][1], article[i][2]];
	}

	const [activeSlice, setActiveSlice] = useState(-1);
	const [isAnimating, setIsAnimating] = useState(true);

	const hover_slice = (num, e) => {

		if (num !== activeSlice && !isAnimating) {
			setActiveSlice(num);

			for (var i = 0; i < slice_array.length; i++) {
				slice_array[i].current.style.opacity = '0.2';
				texts_array[i].current.style.opacity = '0.0';
			}

			slice_array[num - 1].current.style.opacity = '1.0';
			texts_array[num - 1].current.style.opacity = '1.0';
		}
	}

	const activateHover = () => {
		setIsAnimating(false);
	}

	// INVIEW
	const { ref: inViewRef, inView } = useInView({ threshold: 0.5 });
	const [firstLoad, setFirstLoad] = useState(true);

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[inViewRef],
	);

	useEffect(() => {
		if (firstLoad && inView) {
			setFirstLoad(false);

			// push google analytics
			pushGTM('06_wheel')

			// WHEEL ANIMATION
			const slices = [q("#slice1"), q("#slice2"), q("#slice3"), q("#slice4"), q("#slice5"), q("#slice6")]

			// SET
			gsap.set(q(".hole2"), { opacity: 0 });
			gsap.set(q('#arrow'), { scaleY: 0.55, opacity: 1 });
			gsap.set(q('#arrow_icon'), { rotateZ: 80, scale: 0, opacity: 1 });
			gsap.set(q('.design_graphics_text'), { x: '-=0px', y: '+=50px', opacity: 0, transformOrigin: "top center" });
			gsap.set(q('.wheel_grid'), { opacity: 1 });

			// arrow 
			gsap.set(q('#design_arrow'), { scaleY: 0.5, opacity: 0, x: '-=74px', y: '+=48px', rotateZ: -39, transformOrigin: "center bottom" });
			gsap.set(q('#design_arrow_up'), { scaleY: 0.5, opacity: 0, x: '+=0px', y: '+=0px', rotateZ: 0, transformOrigin: "center bottom" });

			const start_delay = 0;
			const defDuration = 0.075;
			const defDelay = 0.075;

			// init hole + arrow
			gsap.to(q('#arrow_icon'), { scale: 1, opacity: 1, delay: (start_delay + 0.3), duration: 0.5, ease: "back.out(2)" });
			gsap.to(q('.hole2'), { opacity: 1, delay: (start_delay + 0.6), duration: 0.3 });
			gsap.to(q('#arrow_icon'), { rotateZ: 717, delay: start_delay + 0.3, duration: 1.8, ease: "power2.out" });

			// loop slices
			for (var i = 0; i < slices.length; i++) {
				gsap.to(slices[i], { opacity: 1, duration: defDuration, delay: start_delay + (defDelay * i + 0.7) });
				gsap.to(slices[i], { opacity: 0.2, duration: 0.3, delay: start_delay + 1.0 + (defDelay * i) + (0.01 * (i * i)) });
			}

			// show design arrow+text
			gsap.to(q('#design_arrow'), { scaleY: 1, opacity: 1, delay: (start_delay + 1.7), duration: 0.5, ease: "sine.out" });
			gsap.to(q('#design_arrow_up'), { scaleY: 0.9, opacity: 1, delay: (start_delay + 1.7), duration: 0.5, ease: "sine.out" });
			gsap.to(q('.design_graphics_text'), { x: 0, y: 0, opacity: 1, delay: (start_delay + 1.9), duration: 0.3, ease: "sine.out" });

			// show slice 1 + text 1
			// gsap.to(q('#slice1'), { opacity: 1, delay: (start_delay + 4.1), duration: 0.7 }); // 2.1
			gsap.to(q('#slice1'), { opacity: 1, delay: (start_delay + 4.1), duration: 0.7, oncomplete: activateHover }); // 2.1
			gsap.to(q('#wheel_text1'), { opacity: 1, delay: (start_delay + 4.1), duration: 0.3 });

		}

	}, [firstLoad, inView, q]);
	// END INVIEW

	return (
		<section className="wheel" ref={setRefs}>
			<div className="wheel_container">

				<div className="wheel_grid">

					<div className="column_text">

						{
							// loop texts
							texts.map((text, index) => (
								<div key={index} id={`wheel_text${index + 1}`} className="text_item" ref={texts_array[index]}>
									<div className="col_1">
										<img src={require(`../assets/img/wheel/${text[2]}.svg`)} alt={`Icon ${text[0]}`} />
									</div>
									<div className="col_2">
										<h3>{text[0]}</h3>
										<p>{text[1]}</p>
									</div>
								</div>
							))
						}

					</div>
					{/* end column_text */}

					<div className="column_graphics">
						<img id="cover" src={design_cover} alt="Design cover" />

						<div className="design_graphics_arrow">
							<img id="design_arrow" src={design_arrow} alt="Design Arrow" />
							<img id="design_arrow_up" src={design_arrow} alt="Design Arrow UP" />
						</div>

						<div className="graph_container">

							<div id="middle_items">
								<div className="hole"></div>
								<div className="hole2"></div>
								<div id="arrow">
									<div id="arrow_icon">
										<img className="shadow" src={arrow_circle} alt="Arrow" />
									</div>
								</div>
							</div>

							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
								x="0"
								y="0"
								enableBackground="new 0 0 677.4 674.7"
								version="1.1"
								viewBox="0 0 677.4 674.7"
								xmlSpace="preserve"
							>
								<g className="slice1" id="slice1" onMouseOver={(e) => hover_slice(1, e)} onClick={(e) => hover_slice(1, e)} ref={slice1}>
									<path
										fill="#15AACC"
										d="M338.7 97.2c124.1 0 230.1 40.2 292.2 110.7L338.7 318.5V97.2z"
									></path>
									<polygon fill="#FFFFFF" opacity="0.2" points="447.9,160.8 447.9,219 459.5,219 459.5,226.5 421.3,226.5 421.3,219 432.9,219 432.9,174.2 420.5,181.4 413.9,176.4 436.6,160.8" />
								</g>
								<g className="slice2" id="slice2" onMouseOver={(e) => hover_slice(2, e)} onClick={(e) => hover_slice(2, e)} ref={slice2}>

									<path
										fill="#90D2E9"
										d="M630.9 207.8c62 70.5 62 150.8 0 221.3L338.7 318.5l292.2-110.7z"
									></path>
									<defs>
										<path
											id="SVGID_1_"
											d="M677.4 318.5v37.7c0 37.7-15.5 75.4-46.5 110.7v-37.7c31-35.3 46.5-73 46.5-110.7z"
											enableBackground="new"
										></path>
									</defs>
									<clipPath id="SVGID_00000083056223938483495470000002878816680983904899_">
										<use overflow="visible" xlinkHref="#SVGID_1_"></use>
									</clipPath>
									<g clipPath="url(#SVGID_00000083056223938483495470000002878816680983904899_)">
										<path
											fill="#6D9EAE"
											d="M677.4 318.5v37.7c0 37.7-15.5 75.4-46.5 110.7v-37.7c31-35.3 46.5-73 46.5-110.7"
										></path>
									</g>
									<path fill="#0AABCD" opacity="0.5" d="M545,280.3c4.4,0,8.1,0.3,11.2,1c3.1,0.7,5.7,1.6,7.7,2.9c2,1.3,3.4,2.8,4.4,4.7c0.9,1.8,1.4,3.9,1.4,6.3     c0,1.3-0.2,2.6-0.6,3.9c-0.4,1.3-1,2.7-1.8,4.2c-0.9,1.5-2,3.1-3.3,4.8c-1.4,1.7-3.1,3.7-5,6l-19.7,24.6h29.2v7.8h-46.6v-5.8     l22.1-27.9c2-2.5,3.7-4.8,5-6.7c1.3-1.9,2.3-3.6,3-5c0.7-1.4,1.2-2.7,1.5-3.7c0.3-1.1,0.4-2,0.4-2.8c0-1.3-0.2-2.5-0.6-3.4     c-0.4-0.9-0.9-1.6-1.6-2.2c-0.7-0.5-1.6-0.9-2.7-1.2c-1.1-0.2-2.3-0.3-3.7-0.3c-3.4,0-5.9,0.9-7.5,2.8c-1.5,1.9-2.3,4.5-2.3,8     v3.7h-11.7c-0.4-1.4-0.8-2.7-1.1-3.9c-0.3-1.2-0.5-2.5-0.5-3.9c0-1.7,0.4-3.3,1.3-5c0.9-1.7,2.3-3.1,4.1-4.5     c1.9-1.3,4.3-2.4,7.2-3.2C537.5,280.7,541,280.3,545,280.3z" />

								</g>
								<g className="slice6" id="slice6" onMouseOver={(e) => hover_slice(6, e)} onClick={(e) => hover_slice(6, e)} ref={slice6}>

									<path
										fill="#90D2E9"
										d="M338.7 97.2v221.3L46.5 207.8c62.1-70.5 168.1-110.6 292.2-110.6z"
									></path>

									<path fill="#0AABCD" opacity="0.5" d="M254.6,193.7c1.2,2.1,1.8,4.8,1.8,8v6.4c0,3.5-0.6,6.5-1.7,8.9c-1.1,2.4-2.7,4.4-4.8,5.9     c-2.1,1.5-4.6,2.6-7.5,3.2c-2.9,0.7-6.2,1-9.8,1c-4.1,0-7.7-0.4-10.9-1.1c-3.1-0.7-5.8-1.9-8-3.5c-2.2-1.6-3.8-3.7-4.9-6.2     c-1.1-2.6-1.7-5.7-1.7-9.3v-27c0-3,0.4-5.6,1.3-7.8c0.8-2.1,2-4,3.3-5.4c1.4-1.5,3-2.6,4.8-3.5c1.8-0.9,3.6-1.5,5.5-2     c1.9-0.5,3.8-0.8,5.7-0.9c1.9-0.1,3.6-0.2,5.2-0.2c4,0,7.5,0.3,10.3,1c2.9,0.7,5.2,1.6,7,2.7c1.8,1.1,3.1,2.5,4,4     c0.8,1.5,1.2,3.1,1.2,4.8c0,0.9-0.1,1.9-0.4,3c-0.3,1.1-0.7,2.2-1.2,3.2h-11.5v-2c0-2-0.2-3.6-0.6-4.9c-0.4-1.3-0.9-2.2-1.7-3     c-0.8-0.7-1.7-1.2-2.9-1.5c-1.2-0.3-2.6-0.4-4.2-0.4c-3.4,0-5.9,0.7-7.7,2.2c-1.8,1.5-2.7,3.9-2.7,7.2v12.6     c1.5-1.1,3.4-2,5.8-2.7c2.4-0.7,5.2-1.1,8.3-1.1c2.3,0,4.6,0.2,6.9,0.7c2.3,0.5,4.4,1.3,6.3,2.5     C251.8,189.9,253.4,191.6,254.6,193.7z M241.2,211.1v-9.8c0-1.6-0.2-2.9-0.6-4c-0.4-1.1-1-1.9-1.8-2.6c-0.8-0.6-1.7-1.1-2.8-1.4     c-1.1-0.3-2.3-0.4-3.7-0.4c-1.1,0-2.1,0.1-3,0.3c-1,0.2-1.8,0.5-2.7,0.8c-0.8,0.3-1.6,0.7-2.2,1.2c-0.7,0.4-1.3,0.9-1.8,1.3v14.2     c0,3,0.8,5.3,2.4,6.7c1.6,1.4,4.1,2.2,7.5,2.2c3,0,5.2-0.7,6.6-2.1C240.5,216.1,241.2,213.9,241.2,211.1" />
								</g>
								<g className="slice3" id="slice3" onMouseOver={(e) => hover_slice(3, e)} onClick={(e) => hover_slice(3, e)} ref={slice3}>
									<path
										fill="#15AACC"
										d="M338.7 318.5l292.2 110.7c-62 70.5-168.1 110.7-292.2 110.7V318.5z"
									></path>
									<defs>
										<path
											id="SVGID_00000084521842741117203430000003123090085574508672_"
											d="M630.9 429.2v37.7c-62 70.5-168.1 110.7-292.2 110.7v-37.7c124.1-.1 230.1-40.2 292.2-110.7z"
											enableBackground="new"
										></path>
									</defs>
									<clipPath id="SVGID_00000122688846737559723790000011092654891412772749_">
										<use
											overflow="visible"
											xlinkHref="#SVGID_00000084521842741117203430000003123090085574508672_"
										></use>
									</clipPath>
									<g clipPath="url(#SVGID_00000122688846737559723790000011092654891412772749_)">
										<path
											fill="#158098"
											d="M630.9 429.2v37.7c-13 14.8-28 28.3-44.7 40.3v-37.7c16.6-12 31.6-25.5 44.7-40.3"
										></path>
										<path
											fill="#158199"
											d="M586.2 469.5v37.7c-17.1 12.4-36 23.2-56.5 32.5V502c20.4-9.3 39.3-20.1 56.5-32.5M529.7 502v37.7c-18.7 8.5-38.7 15.6-59.8 21.3v-37.7c21.1-5.8 41.1-12.9 59.8-21.3M469.9 523.2V561c-24.6 6.7-50.7 11.4-78 14v-37.7c27.3-2.7 53.4-7.4 78-14.1M391.9 537.3V575c-17.3 1.7-35.1 2.5-53.2 2.5v-37.7c18.1 0 35.9-.8 53.2-2.5"
										></path>
									</g>
									<path fill="#FFFFFF" opacity="0.2" d="M440.5,401.5c4.3,0,7.9,0.3,11,1c3,0.6,5.5,1.6,7.5,2.8c1.9,1.2,3.4,2.8,4.3,4.6c0.9,1.8,1.4,3.9,1.4,6.2v4     c0,1.9-0.4,3.5-1.1,5c-0.7,1.5-1.7,2.7-2.8,3.8c-1.2,1.1-2.5,2-3.9,2.7c-1.5,0.8-3,1.4-4.5,1.8v0.3c2,0.4,3.8,1,5.4,1.7     c1.7,0.7,3.1,1.6,4.4,2.7c1.2,1.1,2.2,2.4,2.9,4c0.7,1.6,1.1,3.4,1.1,5.6v4.3c0,3-0.7,5.6-2,7.6c-1.3,2.1-3.1,3.7-5.4,5     c-2.3,1.3-4.9,2.2-7.9,2.8c-3,0.6-6.2,0.8-9.6,0.8c-7.7,0-13.5-1.2-17.5-3.7c-4-2.4-6-5.9-6-10.3c0-1.1,0.1-2.3,0.4-3.6     c0.2-1.3,0.6-2.7,1.1-4.1h11.8v3.3c0,2.1,0.3,3.8,0.8,5.2c0.5,1.4,1.2,2.5,2.1,3.3c0.9,0.8,2,1.4,3.2,1.8     c1.3,0.4,2.6,0.5,4.1,0.5c1.4,0,2.8-0.1,3.9-0.4c1.2-0.3,2.2-0.7,3-1.3c0.8-0.6,1.4-1.4,1.9-2.4c0.4-1,0.6-2.1,0.6-3.5V446     c0-1.4-0.2-2.6-0.6-3.7c-0.4-1.1-1-2-1.8-2.7c-0.8-0.7-1.9-1.3-3.2-1.7c-1.3-0.4-3-0.6-4.9-0.6h-6.4v-7.1h6.4     c1.2,0,2.4-0.1,3.5-0.4c1.1-0.3,2.1-0.7,2.9-1.3c0.8-0.6,1.5-1.4,1.9-2.3c0.5-1,0.7-2.1,0.7-3.5v-6.9c0-2.4-0.7-4.2-2.2-5.4     c-1.4-1.1-3.6-1.7-6.4-1.7c-3.2,0-5.6,0.9-7.3,2.8c-1.7,1.9-2.6,4.5-2.6,8v3.7h-11.7c-0.4-1.3-0.8-2.6-1.1-3.8     c-0.3-1.3-0.5-2.6-0.5-4c0-1.7,0.5-3.3,1.4-5c0.9-1.7,2.3-3.1,4.2-4.5c1.9-1.3,4.3-2.4,7.2-3.2     C433.1,401.9,436.6,401.5,440.5,401.5z" />
								</g>
								<g className="slice5" id="slice5" onMouseOver={(e) => hover_slice(5, e)} onClick={(e) => hover_slice(5, e)} ref={slice5}>
									<path
										fill="#15AACC"
										d="M46.5 207.8l292.2 110.7L46.5 429.2c-62-70.5-62-150.9 0-221.4z"
									></path>
									<defs>
										<path
											id="SVGID_00000159464189745142733630000013441317606971403161_"
											d="M0 356.2v-37.7c0 37.7 15.5 75.4 46.5 110.7v37.7C15.5 431.6 0 393.9 0 356.2z"
											enableBackground="new"
										></path>
									</defs>
									<clipPath id="SVGID_00000082335765365210728220000003572169067801509815_">
										<use
											overflow="visible"
											xlinkHref="#SVGID_00000159464189745142733630000013441317606971403161_"
										></use>
									</clipPath>
									<g clipPath="url(#SVGID_00000082335765365210728220000003572169067801509815_)">
										<path
											fill="#158199"
											d="M46.5 429.2v37.7c-4.9-5.6-9.4-11.2-13.6-16.9v-37.7c4.2 5.6 8.7 11.3 13.6 16.9"
										></path>
										<path
											fill="#158199"
											d="M33 412.2V450c-11.5-15.9-20-32.1-25.5-48.6v-37.7c5.5 16.4 13.9 32.7 25.5 48.5"
										></path>
										<path
											fill="#158098"
											d="M7.5 363.6v37.7c-5-14.9-7.5-30.1-7.5-45.2v-37.7c0 15.2 2.5 30.3 7.5 45.2"
										></path>
									</g>

									<path fill="#FFFFFF" opacity="0.2" d="M147.4,280.3v8.8h-30.4v16.2c1.6-1.2,3.7-2.2,6.2-2.9c2.5-0.7,5.1-1,7.8-1c6.2,0,10.8,1.4,13.7,4.1     c2.9,2.7,4.3,6.8,4.3,12.1v11.1c0,3.4-0.7,6.2-2,8.5c-1.3,2.3-3.1,4.1-5.3,5.5c-2.2,1.4-4.7,2.4-7.6,2.9s-5.8,0.9-8.9,0.9     c-3.7,0-7-0.3-9.8-1c-2.9-0.7-5.2-1.6-7.2-2.8c-1.9-1.2-3.4-2.7-4.4-4.4c-1-1.7-1.5-3.7-1.5-5.8c0-2.3,0.5-4.9,1.5-7.6h11.8v5.4     c0,3.1,0.7,5.4,2.1,6.7s3.7,2.1,6.8,2.1c3.2,0,5.5-0.8,6.8-2.3c1.3-1.5,2-3.7,2-6.5v-12.7c0-1.6-0.2-3-0.6-4     c-0.4-1-1-1.8-1.8-2.4c-0.7-0.6-1.6-1-2.6-1.2c-1-0.2-2.1-0.3-3.3-0.3c-2.2,0-4.1,0.4-5.6,1.2c-1.6,0.8-2.8,1.8-3.9,2.9h-11.2     v-33.5H147.4z" />
								</g>
								<g className="slice4" id="slice4" onMouseOver={(e) => hover_slice(4, e)} onClick={(e) => hover_slice(4, e)} ref={slice4}>

									<path
										fill="#90D2E9"
										d="M338.7 318.5v221.3c-124.1 0-230.1-40.2-292.2-110.7l292.2-110.6z"
									></path>
									<defs>
										<path
											id="SVGID_00000067198994679782934130000005591439446351677359_"
											d="M338.7 539.8v37.7c-124.1 0-230.1-40.2-292.2-110.7v-37.7c62.1 70.6 168.1 110.7 292.2 110.7z"
											enableBackground="new"
										></path>
									</defs>
									<clipPath id="SVGID_00000139265466772831833710000012656030547049033358_">
										<use
											overflow="visible"
											xlinkHref="#SVGID_00000067198994679782934130000005591439446351677359_"
										></use>
									</clipPath>
									<g clipPath="url(#SVGID_00000139265466772831833710000012656030547049033358_)">
										<path
											fill="#6E9FAF"
											d="M338.7 539.8v37.7c-78.9 0-150.6-16.3-208.2-46.2v-37.7c57.6 30 129.3 46.2 208.2 46.2"
										></path>
										<path
											fill="#6D9EAF"
											d="M130.5 493.6v37.7c-21-10.9-40.1-23.6-57.1-38.1v-37.7c17 14.5 36.1 27.2 57.1 38.1"
										></path>
										<path
											fill="#6D9EAE"
											d="M73.4 455.5v37.7c-9.7-8.2-18.7-17-26.9-26.4v-37.7c8.2 9.4 17.2 18.2 26.9 26.4"
										></path>
									</g>

									<path fill="#0AABCD" opacity="0.5" d="M246.5,448.2h9.3v7.8h-9.3v11.8h-14.1V456h-33.2v-5.2l29.2-48.7h18.1V448.2z M232.4,438.5l1.4-24.8H232     l-17.3,34.4h17.7V438.5" />
								</g>
							</svg>


						</div> {/* graph_container */}
					</div> {/* column_graphics */}

					{/* design col */}
					<div className="column_design">
						<div className="design_graphics_text">
							<div className="col_1">
								<img src={icon_design} alt="Design icon" />
							</div>
							<div className="col_2">
								<h3>{wheel_info.design_header}</h3>
								<p>{wheel_info.design_text}</p>

								<div className="accordion_container">
									<Accordion text={wheel_info.design_accordion} />
								</div>
							</div>
						</div>
					</div>
					{/* end design col */}

				</div> {/* wheel_grid */}
			</div>
			{/* wheel_container */}

		</section>
	);
};

export default Wheel;

