import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('web57832-app-wheel'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);