import Home from './Views/Home';
// import Notfound from './Components/404'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

	return (
		<Router>

			<div className="App">

				{/* development */}
				{/* <Routes>
					<Route exact path="/" element={<Home lang="se" />} />
					<Route exact path="/en" element={<Home lang="en" />} />
					<Route path="*" element={<Notfound />} />
				</Routes> */}

				{/* https://clients.milttonsweden.se/Lighter/test/6/ */}
				{/* https://www.lighteragenda.nu/test/1/ */}
				<Routes>
					{/* <Route exact path="" element={<Home lang="se" />} /> */}
					<Route path="*" element={<Home lang="en" />} />
					
					{/* <Route exact path="/en" element={<Home lang="en" />} />
					<Route path="*" element={<Notfound />} /> */}
				</Routes>

			</div>

			</Router>

	);
}

export default App;
