
export function triggerScroll() {
    const comps = document.querySelectorAll('.react-parallax-background-children');

    // add react_parallax_transition class
    // transition 300ms
    comps.forEach(function (val) {
        val.classList.add("react_parallax_transition");
    });
    
    // simulate scroll
    // after 500ms is the accordion animated
    setTimeout(simulateScroll, 500);
    function simulateScroll() {
        window.scrollTo(window.scrollX, window.scrollY - 1);
        window.scrollTo(window.scrollX, window.scrollY + 1);
    }

    // remove react_parallax_transition class
    // after 300ms - the scroll is finished
    setTimeout(remove_class, 800);
    function remove_class() {

        comps.forEach(function (val) {
            val.classList.remove("react_parallax_transition");
        });
    }

    return;
}

export default triggerScroll;
