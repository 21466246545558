import DOMPurify from 'dompurify';
import { useRef, useState} from 'react';
import { gsap } from "gsap";
import triggerScroll from '../Functions/triggerScroll';

// i18
import i18next from 'i18next';
import '../i18n';

const Accordion = ({text}) => {
	const el = useRef();
	const q = gsap.utils.selector(el);
	const [accOpen, setAccOpen] = useState(false);

	const [moreStyle, setMoreStyle] = useState({ 'display': 'inline' });
	const [lessStyle, setLessStyle] = useState({ 'display': 'none' });

	// purifier
	function createMarkup(text) {
		var clean_html = DOMPurify.sanitize(text);
		return {__html: clean_html};
	}

	// click
	const toggleAccordion = () => {
		setAccOpen(!accOpen);

		if(accOpen){
			setMoreStyle({ 'display': 'inline' });
			setLessStyle({ 'display': 'none' });
			gsap.to(q(".accordion_content"), { duration: 0.5, delay: 0.0, height: '0px', ease: "Sine.easeInOut" });
		}else{
			setMoreStyle({ 'display': 'none' });
			setLessStyle({ 'display': 'inline' });
			gsap.to(q(".accordion_content"), { duration: 0.5, delay: 0.0, height: 'auto', ease: "Sine.easeInOut" });
		}
		
		triggerScroll();

	} // toggleAccordion

	return (
		<div className="accordion" ref={el}>
			<div className="accordion_content" dangerouslySetInnerHTML={createMarkup(text)} />
			<div className="accordion_button" onClick={toggleAccordion}>
				<div className="read_more_text" style={moreStyle}>{i18next.t('Read_more_btn_text')}</div>
				<div className="read_less_text" style={lessStyle}>{i18next.t('Read_less_btn_text')}</div>
			</div>
		</div>
	);
}
 
export default Accordion;