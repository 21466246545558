// i18
import i18next from 'i18next';
import '../i18n';

const pushGTM = (ref, pageview) => {

  let lang_code = i18next.language.toUpperCase();
  let ref_name = ref + '_lang_' + lang_code;

  // show component
  if (pageview !== true){

    var dataObject = {
      'event': 'show_module_event',
      'category': 'shown_module',
      'show_module_variable': ref_name,
      'label': ref_name
    };

    if(typeof window.dataLayer != 'undefined'){
      window.dataLayer.push(dataObject);
    }
    
    // console.log('GA ', dataObject);

  // is pageview
  }else{
    
    var dataObject2 = {
      'event': 'pageview_trigger_event_name',
      'page_name_variable': ref
    };

    if (typeof window.dataLayer != 'undefined') {
      window.dataLayer.push(dataObject2);
    }
    // console.log('GA pageview ', dataObject2);
  }


  return;

}

export {pushGTM}